<template>
  <list :dataSet="dataSet"></list>
</template>



<script>

import List from "@/views/Insights/List/List";

export default {
  name: "Articles",
  components: {List},
  data(){
    return{
      title:'Articles',
      dataSet:'articles'
    }
  },
  mounted() {
    document.title = "Articles";
  }
}
</script>

<style scoped>

</style>